<template>
	<div id="gestionEnt">
		<!-- en-tête nom et logo entreprise  -->
		<div class="sm:flex items-center m-auto text-center sm:text-left">
			<div class="w-full sm:w-1/2 flex mt-auto">
				<vs-button
					color="#2c2c2c"
					type="flat"
					icon-pack="feather"
					icon="icon-arrow-left"
					class="inline"
					@click="$router.push({ path: '/' })"
				/>

				<h2 class="inline ml-2" style="line-height: 35px">Membres</h2>
			</div>
			<div v-if="organization" class="w-full sm:w-1/2 flex">
				<img
					v-show="getImageUrl(organization)"
					:src="getImageUrl(organization)"
					class="rounded-full shadow-md ml-auto"
					height="50px"
					width="50px"
					style="object-fit: contain"
				/>
				<h2 class="title-sinao mt-auto mb-auto ml-4 mr-4">
					{{ organization.name }}
				</h2>
			</div>
		</div>

		<!-- Card principale -->
		<vx-card class="mt-4 text-center shadow-none border">
			<h4 class>{{ $t("Gestion des membres") }}</h4>
			<p class="paragraph">
				Ajoutez ici les membres de votre organisation (salariés et non salariés) autorisés à accéder à votre
				espace. Vous êtes actuellement en plan <strong>{{ subscription.plan.nickname.toUpperCase() }}</strong> ,
				qui inclut
				{{ subscription.plan.limits.users > 0 ? subscription.plan.limits.users : "∞" }} utilisateur(s).
				<br />Vous avez actuellement {{ policies.length + invites.length }} utilisateur(s).
			</p>

			<p>{{ $t("Les utilisateurs supplémentaires sont facturés 10€ / utilisateur.") }}</p>

			<s-button class="mt-5" :to="{ name: 'Invite', params: { appId } }" icon="icon-user-plus" type="success">
				{{ $t("Ajouter un nouveau membre") }}
			</s-button>

			<h4 class="mb-5 pt-5">{{ $t("Membres") }}</h4>

			<!-- Membres -->
			<vs-table
				v-if="policies && policies.length > 0"
				ref="tableUsers"
				class="text-left relative"
				stripe
				:data="policies"
				hover-flat
			>
				<template slot="thead">
					<vs-th />
					<vs-th>{{ $t("Nom") }}</vs-th>
					<vs-th>{{ $t("Droits") }}</vs-th>
					<vs-th>{{ $t("Derniere Connexion") }}</vs-th>
					<vs-th>{{ $t("Actions") }}</vs-th>
				</template>

				<template slot-scope="{ data }">
					<vs-tr v-for="(member, index) in data" :key="index">
						<!-- Colonne 1 : photo avec gestion si admin -->
						<Fragment v-if="member && member.user">
							<vs-td class="photoCol">
								<vs-tooltip
									v-if="index === 0"
									:text="adminTooltip.text"
									:title="adminTooltip.title"
									position="top"
								>
									<vs-icon
										v-if="index === 0"
										class="absolute"
										icon="star"
										color="var(--warning)"
										size="1.5rem"
									/>
									<img
										:src="getImageUrl(member.user)"
										class="rounded-full"
										height="50px"
										width="50px"
										alt
									/>
								</vs-tooltip>
								<img
									v-else
									:src="getImageUrl(member.user)"
									class="rounded-full"
									height="50px"
									width="50px"
									alt
								/>
							</vs-td>
							<!-- Colonne 2 : prénom + nom -->
							<vs-td>
								<vs-tooltip
									v-if="index === 0"
									:text="adminTooltip.text"
									:title="adminTooltip.title"
									position="top"
								>
									{{ member.user.firstname }} {{ member.user.lastname }}
									<div style="font-size: 12px; color: var(--black)">{{ member.user.email }}</div>
								</vs-tooltip>
								<div v-else>
									{{ member.user.firstname }} {{ member.user.lastname }}
									<div style="font-size: 12px; color: var(--black)">{{ member.user.email }}</div>
								</div>
							</vs-td>

							<!-- Colonne 3 : Droits cliquable qui ouvre la showPoliciesPopups -->
							<vs-td class="w-1/3">
								<vs-tooltip
									v-if="index === 0"
									:text="adminTooltip.text"
									:title="adminTooltip.title"
									position="top"
								>
									<div>
										<p>{{ $t("Administrateur") }}</p>
									</div>
								</vs-tooltip>
								<div
									v-else
									class="align-vertical text-link hover:title-sinao cursor-pointer w-full pt-3 pb-3 no-underline hover:underline text-lg"
									@click="openPoliciesPopup(member)"
								>
									<p v-if="allPolicies && member.policy_profile_id">
										{{ getPolicyById(member.policy_profile_id).name }}
									</p>
								</div>
							</vs-td>

							<vs-td>
								<vs-tooltip
									v-if="index === 0"
									:text="adminTooltip.text"
									:title="adminTooltip.title"
									position="top"
								>
									{{ toDate(member.user.last_access_at) }}
								</vs-tooltip>
								<div v-else>
									{{ toDate(member.user.last_access_at) }}
								</div>
							</vs-td>

							<!-- Colonne 4 : Actions (suppression utilisateur avec gestion admin) -->
							<vs-td>
								<s-button
									radius
									size="large"
									:type="`border-small${index === 0 ? '' : '-danger'}`"
									icon-pack="feather"
									icon="icon-delete"
									:class="{ 'cursor-not-allowed': index == 0 }"
									@click="index !== 0 && confirmDelAccess(member)"
								/>
							</vs-td>
						</Fragment>
					</vs-tr>
				</template>
			</vs-table>

			<!-- Invitations -->
			<template v-if="invites && invites.length > 0">
				<h4 class="mb-5 mt-10">{{ $t("Invitations en attente") }}</h4>

				<!-- On peut rajouter les attributs pagination & search si trop d'utilisateurs max-items="x" -->
				<vs-table v-if="invites" ref="tableInvite" class="text-left relative" stripe :data="invites" hover-flat>
					<template slot="thead">
						<vs-th />
						<vs-th>{{ $t("Nom") }}</vs-th>
						<vs-th>{{ $t("Email") }}</vs-th>
						<vs-th>{{ $t("Action") }}</vs-th>
					</template>

					<template slot-scope="{ data }">
						<vs-tr v-for="invite in data" :key="invite.id">
							<!-- Colonne 1 : photo avec gestion si admin -->
							<vs-td class="photoCol">
								<img
									:src="`https://sinao.app/services/avatar/${invite.firstname}${invite.lastname}/${invite.firstname[0]}${invite.lastname[0]}`"
									class="rounded-full"
									height="50px"
									width="50px"
									alt
								/>
							</vs-td>

							<!-- Colonne 2 : prénom + nom -->
							<vs-td>{{ invite.firstname }} {{ invite.lastname }}</vs-td>

							<!-- Colonne 3 : email -->
							<vs-td>{{ invite.email }}</vs-td>

							<!-- Colonne 4 : action -->
							<vs-td>
								<div class="flex">
									<vs-button
										class="ml-3"
										radius
										size="large"
										color="danger"
										type="border"
										icon-pack="feather"
										icon="icon-delete"
										@click="confirmDelInvite(invite)"
									/>
								</div>
							</vs-td>
						</vs-tr>
					</template>
				</vs-table>
			</template>

			<!-- Popup pour choisir les nouveaux policiesList -->
			<vs-popup
				v-if="selectedMember"
				:title="$t('Choisir les nouveaux droits d un membre')"
				:active.sync="showPoliciesPopup"
			>
				<!-- Div contenant logo + nom/prenom de l'utilisateur qu'on modifie -->
				<div class="flex flex-l items-center justify-center pbt-30">
					<img
						:src="getImageUrl(selectedMember.user)"
						class="rounded-full shadow-md mr-2"
						height="50px"
						width="50px"
					/>
					<h4>
						{{ selectedMember.user.firstname }}
						{{ selectedMember.user.lastname }}
					</h4>
				</div>

				<!-- Div + table des policiesList pour selectionner -->
				<div class="vx-row">
					<vs-table
						v-if="allPolicies"
						v-model="selectedPolicy"
						:data="allPolicies"
						hover-flat
						@selected="updatePolicy"
					>
						<template slot="thead">
							<vs-th class="w-1/3"> {{ $t("Nom") }} </vs-th>
							<vs-th>{{ $t("Description") }}</vs-th>
						</template>

						<template slot-scope="{ data }">
							<vs-tr
								v-for="(policy, index) in data"
								:key="index"
								:data="policy"
								class="cursor-pointer hover:bg-sinao hover:text-black hover:bg-border"
								style="text-align: left"
							>
								<vs-td class="w-1/3">
									{{ policy.name || "" }}
								</vs-td>
								<vs-td>
									{{ policy.description || "" }}
								</vs-td>
							</vs-tr>
						</template>
					</vs-table>
				</div>
			</vs-popup>
		</vx-card>
	</div>
</template>

<script>
	import { Fragment } from "vue-fragment";
	import { mapGetters } from "vuex";
	import i18n from "../i18n";

	export default {
		components: {
			Fragment
		},
		data() {
			return {
				appId: this.$route.params.appId,
				app: null,
				organization: null,
				policies: null,
				invites: null,
				allPolicies: null,
				showPoliciesPopup: false,
				// permet d'appeler la fonction updatePolicy depuis la table dans popup
				selectedPolicy: null,
				selectedMember: null,
				selectedInvite: null,
				adminTooltip: {
					text: i18n.t(
						"Accès total et seule personne à voir et pouvoir gérer les pages Abonnement, Gérer les membres, Connexions API, Gérer les comptes connectés (Importation bancaire)."
					),
					title: i18n.t("Propriétaire de l’espace Sinao.")
				}
			};
		},
		computed: {
			...mapGetters(["getImageUrl"])
		},
		watch: {
			app() {
				const { organization, policies, invites, admin, subscription } = this.app;

				this.organization = organization;
				this.policies = policies;
				this.invites = invites;
				this.subscription = subscription;

				this.policies.unshift({
					user_id: admin.id,
					policy_profile_id: 6,
					app_id: this.appId,
					user: admin
				});
			}
		},
		async created() {
			try {
				this.app = await this.$store.dispatch("getAppByAppId", this.appId);
				this.allPolicies = await this.$store.dispatch("getPolicies", this.appId);
			} catch (error) {
				this.$router.push({ name: "Apps" });
			}
		},
		methods: {
			getPolicyById(id) {
				if (this.allPolicies) {
					return this.allPolicies.find(policy => id === policy.id);
				}
			},
			// Fonction qui permet d'ouvrir la popup des policiesList et de passer l'utilisateur selectionné dans l'objet member
			openPoliciesPopup(member) {
				this.selectedMember = member;

				this.showPoliciesPopup = true;
			},
			// Appeler lors d'un clic selected dans la table popup des policiesList, permet de lancer une requete post modif access
			updatePolicy() {
				this.showPoliciesPopup = false;

				if (this.selectedMember && this.selectedPolicy) {
					const { user } = this.selectedMember;

					const payload = {
						appId: this.appId,
						userId: user.id,
						policyProfileId: this.selectedPolicy.id
					};

					this.$store
						.dispatch("updatePolicy", payload)
						.then(async () => {
							this.app = await this.$store.dispatch("getAppByAppId", this.appId);
						})
						.catch(() => {
							this.$vs.notify({
								title: i18n.t("Impossible de changer les droit de l'utilisateur"),
								text: `L'invitation de ${user.firstname} ${user.lastname} n'a pas pu être renouvelée. Raison : Application introuvable.`,
								color: "danger",
								iconPack: "feather",
								icon: "icon-alert-triangle",
								time: 15000
							});
						});
				}

				this.selectedMember = null;
				this.selectedPolicy = null;
			},
			// TODO : NOT USED, NEED TO BE IMPLEMENTED
			// supprime et recrée une invitation avec les meme valeur
			renewInvite(invite) {
				const payload = {
					appId: this.appId,
					email: invite.recipient_user.email,
					profile: invite.profile,
					firstname: invite.recipient_user.firstname,
					lastname: invite.recipient_user.lastname
				};

				this.$store
					.dispatch("sendInviteMail", payload)
					.then(() => {
						this.$vs.notify({
							title: i18n.t("Invitation renouvellée !"),
							text: `Un mail d'invitation a été renvoyé à ${this.inputPrenom} ${this.inputNom}.`,
							color: "success",
							iconPack: "feather",
							icon: "icon-check",
							time: 5000
						});
					})
					.catch(error => {
						this.$vs.notify({
							title: i18n.t("Impossible de renouveler l'invitation"),
							text: i18n.t("L'invitation de ... n'a pas pu être renouvelée.", {
								firstname: this.selectedMember?.user?.firstname,
								lastname: this.selectedMember?.user?.lastname,
								error: error
							}),
							color: "danger",
							iconPack: "feather",
							icon: "icon-alert-triangle",
							time: 15000
						});
					});

				this.selectedInvite = null;
			},
			// permet d'ouvrir un popup (dialog) pour confirmer la suppression d'un utilisateur
			confirmDelInvite(invite) {
				this.selectedInvite = invite;

				this.$vs.dialog({
					type: "confirm",
					color: "danger",
					title: i18n.t("Supprimer l'invitation"),
					text: i18n.t("Êtes-vous certain de vouloir supprimer l'invitation de ...", {
						firstname: this.selectedInvite.firstname,
						lastname: this.selectedInvite.lastname
					}),
					accept: this.delInvite,
					acceptText: i18n.t("Oui, supprimer l'invitation"),
					cancelText: i18n.t("Non")
				});
			},
			// supprime une invitation
			delInvite() {
				const { id, recipient_user } = this.selectedInvite;

				const payload = {
					appId: this.appId,
					id
				};

				this.$store
					.dispatch("deleteInviteById", payload)
					.then(async () => {
						this.app = await this.$store.dispatch("getAppByAppId", this.appId);

						this.$vs.notify({
							title: i18n.t("Invitation supprimée !"),
							text: i18n.t("L'invitation a bien été supprimée."),
							color: "success",
							iconPack: "feather",
							icon: "icon-check",
							time: 5000
						});
					})
					.catch(error => {
						console.log(error);

						this.$vs.notify({
							title: i18n.t("Impossible de supprimer l'invitation"),
							text: i18n.t("L'invitation de ... n'a pas pu être supprimée", {
								firstname: recipient_user.firstname,
								lastname: recipient_user.lastname
							}),
							color: "danger",
							iconPack: "feather",
							icon: "icon-alert-triangle",
							time: 15000
						});
					});

				this.selectedInvite = null;
			},
			// permet d'ouvrir un popup (dialog) pour confirmer la suppression d'un utilisateur
			confirmDelAccess(member) {
				this.selectedMember = member;

				this.$vs.dialog({
					type: "confirm",
					color: "danger",
					title: i18n.t("Supprimer l'utilisateur"),
					text: i18n.t("Êtes-vous certain de vouloir supprimer l'accès de ...", {
						firstname: this.selectedMember?.user?.firstname,
						lastname: this.selectedMember?.user?.lastname
					}),
					accept: this.deleteAccess,
					acceptText: i18n.t("Oui, supprimer l'accès"),
					cancelText: i18n.t("Non")
				});
			},
			// permet de supprimer l'utilisateur qui est référencé dans l'objet this.selectedMember via DELETE API
			deleteAccess: function () {
				const { user } = this.selectedMember;

				const payload = {
					appId: this.appId,
					userId: user.id
				};

				this.$store
					.dispatch("deleteAccess", payload)
					.then(async () => {
						this.app = await this.$store.dispatch("getAppByAppId", this.appId);

						this.$vs.notify({
							title: i18n.t("Utilisateur supprimée !"),
							text: i18n.t("L'utilisateur n'a pas été supprimée", {
								firstname: user?.firstname,
								lastname: user?.lastname
							}),
							color: "success",
							iconPack: "feather",
							icon: "icon-check",
							time: 5000
						});
					})
					.catch(error => {
						console.error(error);

						this.$vs.notify({
							title: i18n.t("Impossible de supprimer l'utilisateur"),
							text: i18n.t("L'utilisateur n'a pas été supprimée", {
								firstname: user?.firstname,
								lastname: user?.lastname
							}),
							color: "danger",
							iconPack: "feather",
							icon: "icon-alert-triangle",
							time: 15000
						});
					});

				this.selectedMember = null;
			},
			toDate: function (iso) {
				if (iso !== null) {
					const date = new Date(iso);
					const dateString = `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}/${
						date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
					}/${date.getFullYear()}`;
					const hourString = `${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${
						date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
					}`;

					return `${dateString} à ${hourString}`;
				} else {
					return "--";
				}
			},
			// TODO : NOT USED, NEED TO BE IMPLEMENTED
			// permet de savoir si la date de validité en parametre est passée ou non
			isInviteOutOfDate: function (date) {
				date = new Date(date);

				return date.getTime() - Date.now() < 0;
			}
		}
	};
</script>

<style>
	td.td-check,
	th.td-check {
		display: none;
	}

	div.vs-popup--background {
		background-color: rgb(0, 0, 0, 0.15);
	}

	div.vx-card__body,
	.paragraph {
		padding: 35px !important;
	}

	.addButton {
		margin-bottom: 35px !important;
	}

	.pbt-30 {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.con-vs-popup .vs-popup--content {
		padding-top: 0%;
	}

	.photoCol {
		width: 70px;
		text-align: -webkit-center;
	}
</style>
